import type { DealPipelineBoard } from '@/declarations/deal';
import {
  createDealPipeline,
  getDealPipeline,
  getDealPipelines,
  updateDealPipeline,
} from '@/services/luluchat/deals/pipelines';
import { useState } from 'react';
import { useRequest } from 'umi';

export default () => {
  const [dealPipeline, setDealPipeline] = useState<DealPipelineBoard>(
    {} as DealPipelineBoard,
  );
  const {
    data: dealPipelines,
    run: fetchGetDealPipelines,
    loading: isLoadingFetchGetDealPipelines,
  } = useRequest(getDealPipelines, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const { run: fetchGetDealPipeline, loading: isLoadingFetchGetDealPipeline } =
    useRequest(getDealPipeline, {
      manual: true,
      formatResult(res) {
        setDealPipeline(res?.data);
        return res;
      },
    });

  const { run: fetchCreateDealPipeline, loading: isLoadingFetchCreateDealPipeline } =
    useRequest(createDealPipeline, {
      manual: true,
      formatResult(res) {
        return res;
      },
    });

  const { run: fetchUpdateDealPipeline, loading: isLoadingFetchUpdateDealPipeline } =
    useRequest(updateDealPipeline, {
      manual: true,
      formatResult(res) {
        return res;
      },
    });

  return {
    dealPipeline,
    fetchGetDealPipeline,
    isLoadingFetchGetDealPipeline,
    dealPipelines,
    fetchGetDealPipelines,
    isLoadingFetchGetDealPipelines,
    fetchCreateDealPipeline,
    isLoadingFetchCreateDealPipeline,
    fetchUpdateDealPipeline,
    isLoadingFetchUpdateDealPipeline,
    setDealPipeline,
  };
};
