// @ts-ignore
/* eslint-disable */
import request from '../../request';
import { API } from '../../typings';

export async function getCardAttachments(
  { id }: API.GetCardAttachmentsParams,
  options?: { [key: string]: any },
) {
  return request<API.GetCardAttachmentsResult>(`/users/deals/cards/${id}/attachments`, {
    method: 'GET',
    ...(options || {}),
  });
}

export async function createCardAttachment(
  { id, title, url }: API.CreateCardAttachmentParams,
  options?: { [key: string]: any },
) {
  return request<API.CreateCardAttachmentResult>(`/users/deals/cards/${id}/attachments`, {
    method: 'POST',
    data: { title, url },
    ...(options || {}),
  });
}

export async function deleteCardAttachment(
  { id, attachment_uuid }: API.DeleteCardAttachmentParams,
  options?: { [key: string]: any },
) {
  return request<API.DeleteCardAttachmentResult>(
    `/users/deals/cards/${id}/attachments/${attachment_uuid}`,
    {
      method: 'DELETE',
      ...(options || {}),
    },
  );
}
