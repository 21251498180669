import {
  createCardAttachment,
  deleteCardAttachment,
  getCardAttachments,
} from '@/services/luluchat/ticketings/cards/attachments';
import { useRequest } from 'umi';

export default () => {
  const {
    data: cardAttachments,
    run: fetchGetCardAttachments,
    loading: isLoadingFetchGetCardAttachments,
  } = useRequest(getCardAttachments, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const { run: fetchCreateCardAttachment, loading: isLoadingFetchCreateCardAttachment } =
    useRequest(createCardAttachment, {
      manual: true,
      formatResult(res) {
        return res;
      },
    });

  const { run: fetchDeleteCardAttachment, loading: isLoadingFetchDeleteCardAttachment } =
    useRequest(deleteCardAttachment, {
      manual: true,
      formatResult(res) {
        return res;
      },
    });

  return {
    cardAttachments,
    fetchGetCardAttachments,
    isLoadingFetchGetCardAttachments,
    fetchCreateCardAttachment,
    isLoadingFetchCreateCardAttachment,
    fetchDeleteCardAttachment,
    isLoadingFetchDeleteCardAttachment,
  };
};
