import type { DealTag } from '@/declarations/deal';
import {
  createDealTag,
  getDealTag,
  getDealTags,
  syncCardTag,
  updateDealTag,
} from '@/services/luluchat/deals/tags';
import { useState } from 'react';
import { useRequest } from 'umi';

export default () => {
  const [dealTag, setDealTag] = useState<DealTag>({} as DealTag);
  const {
    data: dealTags,
    run: fetchGetDealTags,
    loading: isLoadingFetchGetDealTags,
  } = useRequest(getDealTags, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const { run: fetchGetDealTag, loading: isLoadingFetchGetDealTag } = useRequest(
    getDealTag,
    {
      manual: true,
      formatResult(res) {
        setDealTag(res?.data);
        return res?.data;
      },
    },
  );

  const { run: fetchCreateDealTag, loading: isLoadingFetchCreateDealTag } = useRequest(
    createDealTag,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const { run: fetchUpdateDealTag, loading: isLoadingFetchUpdateDealTag } = useRequest(
    updateDealTag,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const { run: fetchSyncCardTag, loading: isLoadingFetchSyncCardTag } = useRequest(syncCardTag, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  return {
    dealTag,
    fetchGetDealTag,
    isLoadingFetchGetDealTag,
    dealTags,
    fetchGetDealTags,
    isLoadingFetchGetDealTags,
    fetchCreateDealTag,
    isLoadingFetchCreateDealTag,
    fetchUpdateDealTag,
    isLoadingFetchUpdateDealTag,
    setDealTag,
    isLoadingFetchSyncCardTag,
    fetchSyncCardTag,
  };
};
