// @ts-ignore
/* eslint-disable */
import request from '../request';
import { API } from '../typings';

export async function getDealStages(
  body?: API.GetDealStagesParams,
  options?: { [key: string]: any },
) {
  return request<API.GetDealStagesResult>(`/users/deals/stages`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function createDealStage(
  body: API.CreateDealStageParams,
  options?: { [key: string]: any },
) {
  return request<API.CreateDealStageResult>(`/users/deals/stages`, {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}

export async function updateDealStage(
  { id, ...body }: API.UpdateDealStageParams,
  options?: { [key: string]: any },
) {
  return request<API.UpdateDealStageResult>(`/users/deals/stages/${id}`, {
    method: 'PUT',
    data: body,
    ...(options || {}),
  });
}
export async function updateDealStagePosition(
  body: API.UpdateDealStagePositionParams,
  options?: { [key: string]: any },
) {
  return request<API.UpdateDealStagePositionResult>(`/users/deals/stages/update-position`, {
    method: 'PUT',
    data: body,
    ...(options || {}),
  });
}

export async function getDealStage(
  { id, ...body }: API.GetDealStageParams,
  options?: { [key: string]: any },
) {
  return request<API.GetDealStageResult>(`/users/deals/stages/${id}`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function archiveDealStage(
  { id, ...body }: API.ArchiveDealStageParams,
  options?: { [key: string]: any },
) {
  return request<API.ArchiveDealStageResult>(`/users/deals/stages/${id}/archive`, {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}

export async function unarchiveDealStage(
  { id, ...body }: API.UnarchiveDealStageParams,
  options?: { [key: string]: any },
) {
  return request<API.UnarchiveDealStageResult>(`/users/deals/stages/${id}/unarchive`, {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}
