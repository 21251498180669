import type { DealCard } from '@/declarations/deal';
import {
  createDealCard,
  getDealCard,
  getDealCards,
  getDealCardsByContact,
  updateDealCard,
} from '@/services/luluchat/deals/cards/cards';
import { useState } from 'react';
import { useRequest } from 'umi';

export default () => {
  const [dealCard, setDealCard] = useState<DealCard>({} as DealCard);
  const {
    data: dealCardsByContact,
    run: fetchGetDealCardsByContact,
    loading: isLoadingFetchGetDealCardsByContact,
  } = useRequest(getDealCardsByContact, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });
  const {
    data: dealCards,
    run: fetchGetDealCards,
    loading: isLoadingFetchGetDealCards,
  } = useRequest(getDealCards, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const { run: fetchGetDealCard, loading: isLoadingFetchGetDealCard } = useRequest(
    getDealCard,
    {
      manual: true,
      formatResult(res) {
        setDealCard(res?.data);
        return res?.data;
      },
    },
  );

  const { run: fetchCreateDealCard, loading: isLoadingFetchCreateDealCard } = useRequest(
    createDealCard,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const { run: fetchUpdateDealCard, loading: isLoadingFetchUpdateDealCard } = useRequest(
    updateDealCard,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  return {
    dealCard,
    fetchGetDealCard,
    isLoadingFetchGetDealCard,
    dealCardsByContact,
    fetchGetDealCardsByContact,
    isLoadingFetchGetDealCardsByContact,
    dealCards,
    fetchGetDealCards,
    isLoadingFetchGetDealCards,
    fetchCreateDealCard,
    isLoadingFetchCreateDealCard,
    fetchUpdateDealCard,
    isLoadingFetchUpdateDealCard,
    setDealCard,
  };
};
