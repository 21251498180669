// @ts-ignore
/* eslint-disable */
import request from './request';
import { API } from './typings';

export async function getGoogleAccounts(options?: { [key: string]: any }) {
  return request<API.GetGoogleAccountsResult>(`/users/google-accounts`, {
    method: 'GET',
    ...(options || {}),
  });
}

export async function getGoogleCalendars(
  { id }: API.GetGoogleCalendarsParams,
  options?: { [key: string]: any },
) {
  return request<API.GetGoogleCalendarsResult>(`/users/google-accounts/${id}/get-calendars`, {
    method: 'GET',
    ...(options || {}),
  });
}

export async function unlinkGoogleAccount(
  { id }: API.UnlinkGoogleAccountParams,
  options?: { [key: string]: any },
) {
  return request<API.UnlinkGoogleAccountResult>(`/users/google-accounts/unlink/${id}`, {
    method: 'POST',
    ...(options || {}),
  });
}
