import type { DealStage } from '@/declarations/deal';
import {
  createDealStage,
  getDealStage,
  getDealStages,
  updateDealStage,
  updateDealStagePosition,
} from '@/services/luluchat/deals/stages';
import { useState } from 'react';
import { useRequest } from 'umi';

export default () => {
  const [dealStage, setDealStage] = useState<DealStage>({} as DealStage);
  const {
    data: dealStages,
    run: fetchGetDealStages,
    loading: isLoadingFetchGetDealStages,
  } = useRequest(getDealStages, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const { run: fetchGetDealStage, loading: isLoadingFetchGetDealStage } = useRequest(
    getDealStage,
    {
      manual: true,
      formatResult(res) {
        setDealStage(res?.data);
        return res?.data;
      },
    },
  );

  const { run: fetchCreateDealStage, loading: isLoadingFetchCreateDealStage } =
    useRequest(createDealStage, {
      manual: true,
      formatResult(res) {
        return res;
      },
    });

  const { run: fetchUpdateDealStage, loading: isLoadingFetchUpdateDealStage } =
    useRequest(updateDealStage, {
      manual: true,
      formatResult(res) {
        return res;
      },
    });

  const {
    run: fetchUpdateDealStagePosition,
    loading: isLoadingFetchUpdateDealStagePosition,
  } = useRequest(updateDealStagePosition, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  return {
    dealStage,
    fetchGetDealStage,
    isLoadingFetchGetDealStage,
    dealStages,
    fetchGetDealStages,
    isLoadingFetchGetDealStages,
    fetchCreateDealStage,
    isLoadingFetchCreateDealStage,
    fetchUpdateDealStage,
    isLoadingFetchUpdateDealStage,
    setDealStage,
    isLoadingFetchUpdateDealStagePosition,
    fetchUpdateDealStagePosition,
  };
};
