import type { TicketingStage } from '@/declarations/ticketing';
import {
  createTicketingStage,
  getTicketingStage,
  getTicketingStages,
  updateTicketingStage,
  updateTicketingStagePosition,
} from '@/services/luluchat/ticketings/stages';
import { useState } from 'react';
import { useRequest } from 'umi';

export default () => {
  const [ticketingStage, setTicketingStage] = useState<TicketingStage>({} as TicketingStage);
  const {
    data: ticketingStages,
    run: fetchGetTicketingStages,
    loading: isLoadingFetchGetTicketingStages,
  } = useRequest(getTicketingStages, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const { run: fetchGetTicketingStage, loading: isLoadingFetchGetTicketingStage } = useRequest(
    getTicketingStage,
    {
      manual: true,
      formatResult(res) {
        setTicketingStage(res?.data);
        return res?.data;
      },
    },
  );

  const { run: fetchCreateTicketingStage, loading: isLoadingFetchCreateTicketingStage } =
    useRequest(createTicketingStage, {
      manual: true,
      formatResult(res) {
        return res;
      },
    });

  const { run: fetchUpdateTicketingStage, loading: isLoadingFetchUpdateTicketingStage } =
    useRequest(updateTicketingStage, {
      manual: true,
      formatResult(res) {
        return res;
      },
    });

  const {
    run: fetchUpdateTicketingStagePosition,
    loading: isLoadingFetchUpdateTicketingStagePosition,
  } = useRequest(updateTicketingStagePosition, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  return {
    ticketingStage,
    fetchGetTicketingStage,
    isLoadingFetchGetTicketingStage,
    ticketingStages,
    fetchGetTicketingStages,
    isLoadingFetchGetTicketingStages,
    fetchCreateTicketingStage,
    isLoadingFetchCreateTicketingStage,
    fetchUpdateTicketingStage,
    isLoadingFetchUpdateTicketingStage,
    setTicketingStage,
    isLoadingFetchUpdateTicketingStagePosition,
    fetchUpdateTicketingStagePosition,
  };
};
