// @ts-ignore
/* eslint-disable */
import request from '../request';
import { API } from '../typings';

export async function getTicketingPipelines(
  body?: API.GetTicketingPipelinesParams,
  options?: { [key: string]: any },
) {
  return request<API.GetTicketingPipelinesResult>(`/users/ticketing/pipelines`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function createTicketingPipeline(
  body: API.CreateTicketingPipelineParams,
  options?: { [key: string]: any },
) {
  return request<API.CreateTicketingPipelineResult>(`/users/ticketing/pipelines`, {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}

export async function updateTicketingPipeline(
  { id, ...body }: API.UpdateTicketingPipelineParams,
  options?: { [key: string]: any },
) {
  return request<API.UpdateTicketingPipelineResult>(`/users/ticketing/pipelines/${id}`, {
    method: 'PUT',
    data: body,
    ...(options || {}),
  });
}

export async function getTicketingPipeline(
  { id, ...body }: API.GetTicketingPipelineParams,
  options?: { [key: string]: any },
) {
  return request<API.GetTicketingPipelineResult>(`/users/ticketing/pipelines/${id}`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function deleteTicketingPipeline(
  { id }: API.DeleteTicketingPipelineParams,
  options?: { [key: string]: any },
) {
  return request<API.DeleteTicketingPipelineResult>(`/users/ticketing/pipelines/${id}`, {
    method: 'DELETE',
    ...(options || {}),
  });
}
