// @ts-ignore
/* eslint-disable */
import request from '../../request';
import { API } from '../../typings';

export async function getDealCards(
  body?: API.GetDealCardsParams,
  options?: { [key: string]: any },
) {
  return request<API.GetDealCardsResult>(`/users/deals/cards`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function createDealCard(
  body: API.CreateDealCardParams,
  options?: { [key: string]: any },
) {
  return request<API.CreateDealCardResult>(`/users/deals/cards`, {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}

export async function updateDealCard(
  { id, ...body }: API.UpdateDealCardParams,
  options?: { [key: string]: any },
) {
  return request<API.UpdateDealCardResult>(`/users/deals/cards/${id}`, {
    method: 'PUT',
    data: body,
    ...(options || {}),
  });
}

export async function getDealCard(
  { id, ...body }: API.GetDealCardParams,
  options?: { [key: string]: any },
) {
  return request<API.GetDealCardResult>(`/users/deals/cards/${id}`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function getDealCardsByContact(
  body: API.GetDealCardsByContactParams,
  options?: { [key: string]: any },
) {
  return request<API.GetDealCardsByContactResult>(`/users/deals/cards/by-contact`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function archiveDealCard(
  { id, ...body }: API.ArchiveDealCardParams,
  options?: { [key: string]: any },
) {
  return request<API.ArchiveDealCardResult>(`/users/deals/cards/${id}/archive`, {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}

export async function unarchiveDealCard(
  { id, ...body }: API.UnarchiveDealCardParams,
  options?: { [key: string]: any },
) {
  return request<API.UnarchiveDealCardResult>(`/users/deals/cards/${id}/unarchive`, {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}
