// @ts-ignore
/* eslint-disable */
import request from '../request';
import { API } from '../typings';

export async function getDealPipelines(
  body?: API.GetDealPipelinesParams,
  options?: { [key: string]: any },
) {
  return request<API.GetDealPipelinesResult>(`/users/deals/pipelines`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function createDealPipeline(
  body: API.CreateDealPipelineParams,
  options?: { [key: string]: any },
) {
  return request<API.CreateDealPipelineResult>(`/users/deals/pipelines`, {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}

export async function updateDealPipeline(
  { id, ...body }: API.UpdateDealPipelineParams,
  options?: { [key: string]: any },
) {
  return request<API.UpdateDealPipelineResult>(`/users/deals/pipelines/${id}`, {
    method: 'PUT',
    data: body,
    ...(options || {}),
  });
}

export async function getDealPipeline(
  { id, ...body }: API.GetDealPipelineParams,
  options?: { [key: string]: any },
) {
  return request<API.GetDealPipelineResult>(`/users/deals/pipelines/${id}`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function deleteDealPipeline(
  { id }: API.DeleteDealPipelineParams,
  options?: { [key: string]: any },
) {
  return request<API.DeleteDealPipelineResult>(`/users/deals/pipelines/${id}`, {
    method: 'DELETE',
    ...(options || {}),
  });
}
