// @ts-ignore
/* eslint-disable */
import request from '../../request';
import { API } from '../../typings';

export async function getCardComments(
  { id, ...body }: API.GetCardCommentsParams,
  options?: { [key: string]: any },
) {
  return request<API.GetCardCommentsResult>(`/users/ticketing/cards/${id}/comments`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function createCardComment(
  { id, message, attachments }: API.CreateCardCommentParams,
  options?: { [key: string]: any },
) {
  return request<API.CreateCardCommentResult>(`/users/ticketing/cards/${id}/comments`, {
    method: 'POST',
    data: { message, attachments },
    ...(options || {}),
  });
}

export async function updateCardComment(
  { id, comment_id, message }: API.UpdateCardCommentParams,
  options?: { [key: string]: any },
) {
  return request<API.UpdateCardCommentResult>(
    `/users/ticketing/cards/${id}/comments/${comment_id}`,
    {
      method: 'PUT',
      data: { message },
      ...(options || {}),
    },
  );
}

export async function deleteCardComment(
  { id, comment_id }: API.DeleteCardCommentParams,
  options?: { [key: string]: any },
) {
  return request<API.DeleteCardCommentResult>(
    `/users/ticketing/cards/${id}/comments/${comment_id}`,
    {
      method: 'DELETE',
      ...(options || {}),
    },
  );
}
