import type { TicketingPipelineBoard } from '@/declarations/ticketing';
import {
  createTicketingPipeline,
  getTicketingPipeline,
  getTicketingPipelines,
  updateTicketingPipeline,
} from '@/services/luluchat/ticketings/pipelines';
import { useState } from 'react';
import { useRequest } from 'umi';

export default () => {
  const [ticketingPipeline, setTicketingPipeline] = useState<TicketingPipelineBoard>(
    {} as TicketingPipelineBoard,
  );
  const {
    data: ticketingPipelines,
    run: fetchGetTicketingPipelines,
    loading: isLoadingFetchGetTicketingPipelines,
  } = useRequest(getTicketingPipelines, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const { run: fetchGetTicketingPipeline, loading: isLoadingFetchGetTicketingPipeline } =
    useRequest(getTicketingPipeline, {
      manual: true,
      formatResult(res) {
        setTicketingPipeline(res?.data);
        return res;
      },
    });

  const { run: fetchCreateTicketingPipeline, loading: isLoadingFetchCreateTicketingPipeline } =
    useRequest(createTicketingPipeline, {
      manual: true,
      formatResult(res) {
        return res;
      },
    });

  const { run: fetchUpdateTicketingPipeline, loading: isLoadingFetchUpdateTicketingPipeline } =
    useRequest(updateTicketingPipeline, {
      manual: true,
      formatResult(res) {
        return res;
      },
    });

  return {
    ticketingPipeline,
    fetchGetTicketingPipeline,
    isLoadingFetchGetTicketingPipeline,
    ticketingPipelines,
    fetchGetTicketingPipelines,
    isLoadingFetchGetTicketingPipelines,
    fetchCreateTicketingPipeline,
    isLoadingFetchCreateTicketingPipeline,
    fetchUpdateTicketingPipeline,
    isLoadingFetchUpdateTicketingPipeline,
    setTicketingPipeline,
  };
};
