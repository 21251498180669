// @ts-nocheck
import React from 'react';
import initialState from '/Users/angyeowchuan/Documents/luluchat-app/src/.umi-production/plugin-initial-state/models/initialState';
import model0 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/aiAssistant";
import model1 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/billing";
import model2 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/campaign";
import model3 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/channel";
import model4 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/contact";
import model5 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/customAttribute";
import model6 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/customer";
import model7 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/dealCard";
import model8 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/dealCardAttachments";
import model9 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/dealPipeline";
import model10 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/dealStage";
import model11 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/dealTag";
import model12 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/diagram";
import model13 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/flow";
import model14 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/flowTemplateCategories";
import model15 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/flowTemplates";
import model16 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/form";
import model17 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/google";
import model18 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/growthTool";
import model19 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/inbox";
import model20 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/inboxTab";
import model21 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/integration";
import model22 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/media";
import model23 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/messageTemplate";
import model24 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/messenger";
import model25 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/notification";
import model26 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/plan";
import model27 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/quickReply";
import model28 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/reminder";
import model29 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/report";
import model30 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/scheduledMessage";
import model31 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/service";
import model32 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/staffServiceProvider";
import model33 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/tag";
import model34 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/team";
import model35 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/ticketingCard";
import model36 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/ticketingCardAttachments";
import model37 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/ticketingCardComments";
import model38 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/ticketingPipeline";
import model39 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/ticketingStage";
import model40 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/ticketingTag";
import model41 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/user";
import model42 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/webhookVendor";
import model43 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/widget";
import model44 from "/Users/angyeowchuan/Documents/luluchat-app/src/models/workflow";
// @ts-ignore
import Dispatcher from './helpers/dispatcher';
// @ts-ignore
import Executor from './helpers/executor';
// @ts-ignore
import { UmiContext } from './helpers/constant';

export const models = { '@@initialState': initialState, 'aiAssistant': model0, 'billing': model1, 'campaign': model2, 'inboxTab': model20, 'messenger': model24, 'contact': model4, 'reminder': model28, 'inbox': model19, 'notification': model25, 'channel': model3, 'customAttribute': model5, 'customer': model6, 'dealCard': model7, 'dealCardAttachments': model8, 'dealPipeline': model9, 'dealStage': model10, 'dealTag': model11, 'flow': model13, 'diagram': model12, 'flowTemplateCategories': model14, 'flowTemplates': model15, 'form': model16, 'google': model17, 'growthTool': model18, 'integration': model21, 'media': model22, 'messageTemplate': model23, 'plan': model26, 'quickReply': model27, 'report': model29, 'scheduledMessage': model30, 'service': model31, 'staffServiceProvider': model32, 'tag': model33, 'team': model34, 'ticketingCard': model35, 'ticketingCardAttachments': model36, 'ticketingCardComments': model37, 'ticketingPipeline': model38, 'ticketingStage': model39, 'ticketingTag': model40, 'user': model41, 'webhookVendor': model42, 'widget': model43, 'workflow': model44 };

export type Model<T extends keyof typeof models> = {
  [key in keyof typeof models]: ReturnType<typeof models[T]>;
};

export type Models<T extends keyof typeof models> = Model<T>[T]

const dispatcher = new Dispatcher!();
const Exe = Executor!;

export default ({ children }: { children: React.ReactNode }) => {

  return (
    <UmiContext.Provider value={dispatcher}>
      {
        Object.entries(models).map(pair => (
          <Exe key={pair[0]} namespace={pair[0]} hook={pair[1] as any} onUpdate={(val: any) => {
            const [ns] = pair as [keyof typeof models, any];
            dispatcher.data[ns] = val;
            dispatcher.update(ns);
          }} />
        ))
      }
      {children}
    </UmiContext.Provider>
  )
}
