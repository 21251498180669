import type { TicketingTag } from '@/declarations/ticketing';
import {
  createTicketingTag,
  getTicketingTag,
  getTicketingTags,
  syncCardTag,
  updateTicketingTag,
} from '@/services/luluchat/ticketings/tags';
import { useState } from 'react';
import { useRequest } from 'umi';

export default () => {
  const [ticketingTag, setTicketingTag] = useState<TicketingTag>({} as TicketingTag);
  const {
    data: ticketingTags,
    run: fetchGetTicketingTags,
    loading: isLoadingFetchGetTicketingTags,
  } = useRequest(getTicketingTags, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const { run: fetchGetTicketingTag, loading: isLoadingFetchGetTicketingTag } = useRequest(
    getTicketingTag,
    {
      manual: true,
      formatResult(res) {
        setTicketingTag(res?.data);
        return res?.data;
      },
    },
  );

  const { run: fetchCreateTicketingTag, loading: isLoadingFetchCreateTicketingTag } = useRequest(
    createTicketingTag,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const { run: fetchUpdateTicketingTag, loading: isLoadingFetchUpdateTicketingTag } = useRequest(
    updateTicketingTag,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const { run: fetchSyncCardTag, loading: isLoadingFetchSyncCardTag } = useRequest(syncCardTag, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  return {
    ticketingTag,
    fetchGetTicketingTag,
    isLoadingFetchGetTicketingTag,
    ticketingTags,
    fetchGetTicketingTags,
    isLoadingFetchGetTicketingTags,
    fetchCreateTicketingTag,
    isLoadingFetchCreateTicketingTag,
    fetchUpdateTicketingTag,
    isLoadingFetchUpdateTicketingTag,
    setTicketingTag,
    isLoadingFetchSyncCardTag,
    fetchSyncCardTag,
  };
};
