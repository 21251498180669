import {
  createCardComment,
  deleteCardComment,
  getCardComments,
  updateCardComment,
} from '@/services/luluchat/ticketings/cards/comments';
import { useRequest } from 'umi';

export default () => {
  const {
    data: cardComments,
    run: fetchGetCardComments,
    loading: isLoadingFetchGetCardComments,
  } = useRequest(getCardComments, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });

  const { run: fetchCreateCardComment, loading: isLoadingFetchCreateCardComment } = useRequest(
    createCardComment,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const { run: fetchUpdateCardComment, loading: isLoadingFetchUpdateCardComment } = useRequest(
    updateCardComment,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const { run: fetchDeleteCardComment, loading: isLoadingFetchDeleteCardComment } = useRequest(
    deleteCardComment,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  return {
    cardComments,
    fetchGetCardComments,
    isLoadingFetchGetCardComments,
    fetchUpdateCardComment,
    isLoadingFetchUpdateCardComment,
    fetchCreateCardComment,
    isLoadingFetchCreateCardComment,
    fetchDeleteCardComment,
    isLoadingFetchDeleteCardComment,
  };
};
