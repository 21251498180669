import {
  getGoogleAccounts,
  getGoogleCalendars,
  unlinkGoogleAccount,
} from '@/services/luluchat/google';
import { useRequest } from 'umi';
export default () => {
  const {
    data: googleAccounts = [],
    run: fetchGetGoogleAccounts,
    loading: isLoadingFetchGetGoogleAccounts,
  } = useRequest(getGoogleAccounts, { manual: true });

  const {
    data: googleCalendars = [],
    run: fetchGetGoogleCalendars,
    loading: isLoadingFetchGetGoogleCalendars,
  } = useRequest(getGoogleCalendars, { manual: true });

  const { run: fetchUnlinkGoogleAccount, loading: isLoadingFetchUnlinkGoogleAccount } = useRequest(
    unlinkGoogleAccount,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );
  return {
    googleAccounts,
    fetchGetGoogleAccounts,
    isLoadingFetchGetGoogleAccounts,
    googleCalendars,
    fetchGetGoogleCalendars,
    isLoadingFetchGetGoogleCalendars,
    fetchUnlinkGoogleAccount,
    isLoadingFetchUnlinkGoogleAccount,
  };
};
