// @ts-ignore
/* eslint-disable */
import request from '../request';
import { API } from '../typings';

export async function getDealTags(body?: API.GetDealTagsParams, options?: { [key: string]: any }) {
  return request<API.GetDealTagsResult>(`/users/deals/tags`, {
    method: 'GET',
    data: {
      sort_direction: 'desc',
      sort_field: 'priority',
      ...body,
    },
    ...(options || {}),
  });
}

export async function createDealTag(body: API.CreateDealTagParams, options?: { [key: string]: any }) {
  return request<API.CreateDealTagResult>(`/users/deals/tags`, {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}

export async function getDealTag({ id, ...body }: API.GetDealTagParams, options?: { [key: string]: any }) {
  return request<API.GetDealTagResult>(`/users/deals/tags/${id}`, {
    method: 'GET',
    data: body,
    ...(options || {}),
  });
}

export async function updateDealTag(
  { id, ...body }: API.UpdateDealTagParams,
  options?: { [key: string]: any },
) {
  return request<API.UpdateDealTagResult>(`/users/deals/tags/${id}`, {
    method: 'PUT',
    data: body,
    ...(options || {}),
  });
}

export async function deleteDealTag({ id }: API.DeleteDealTagParams, options?: { [key: string]: any }) {
  return request<API.DeleteDealTagResult>(`/users/deals/tags/${id}`, {
    method: 'DELETE',
    ...(options || {}),
  });
}

export async function syncCardTag(
  { id, ...body }: API.SyncCardTagParams,
  options?: { [key: string]: any },
) {
  return request<API.SyncCardTagResult>(`/users/deals/cards/${id}/tags/sync`, {
    method: 'PUT',
    data: body,
    ...(options || {}),
  });
}